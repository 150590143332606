import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/card/card';
import CustomDataLabel from '../../../components/dataLabel/customDataLabel';
import DateLabel from '../../../components/date/dateLabel';
import DataLabel from '../../../components/dataLabel/dataLabel';

import { V1Alpha1Checkpoint } from '../../../api/gen/checkpoints';

interface CheckpointStateProps {
  checkpoint?: V1Alpha1Checkpoint;
}

function CheckpointState({ checkpoint }: CheckpointStateProps): ReactElement {
  const { t } = useTranslation();
  return (
    <Card className="flex flex-col items-start bg-white xl:w-1/2 w-full bg-white p-6 lg:mr-2 mb-2">
      <h1 className="font-semibold text-xl mb-4">{t('trapDetails.state')}</h1>
      {checkpoint?.loraMetadata != null && (
        <CustomDataLabel
          isLoading={checkpoint == null}
          className="w-full justify-between text-md"
          label={t('trapDetails.lastSignal')}
          data={DateLabel({ timestamp: checkpoint?.loraMetadata?.lastSignal })}
        />
      )}

      <CustomDataLabel
        isLoading={checkpoint == null}
        className="w-full justify-between text-md"
        label={t('trapDetails.lastInspection')}
        data={DateLabel({
          timestamp: checkpoint?.inspectionMetadata?.lastInspection
        })}
      />

      {checkpoint?.loraMetadata != null && (
        <DataLabel
          isLoading={checkpoint == null}
          className="w-full justify-between text-md"
          label={t('trapDetails.snr')}
          data={`${checkpoint?.loraMetadata?.snr?.toString()} dBm`}
        />
      )}

      {checkpoint?.loraMetadata != null && (
        <DataLabel
          isLoading={checkpoint == null}
          className="w-full justify-between text-md"
          label={t('trapDetails.rssi')}
          data={`${checkpoint?.loraMetadata?.rssi?.toString()} dB`}
        />
      )}

      {checkpoint?.loraMetadata != null && checkpoint.trapPayload != null && (
        <DataLabel
          isLoading={checkpoint == null}
          className="w-full justify-between text-md"
          label={t('trapDetails.battery')}
          data={`${checkpoint?.trapPayload.batteryVoltage?.toString()} V`}
        />
      )}

      {checkpoint?.loraMetadata != null &&
        checkpoint.temperaturePayload != null && (
          <DataLabel
            isLoading={checkpoint == null}
            className="w-full justify-between text-md"
            label={t('trapDetails.battery')}
            data={`${checkpoint?.temperaturePayload.batteryVoltage?.toString()} V`}
          />
        )}

      {checkpoint?.loraMetadata != null &&
        checkpoint.carbonDioxidePayload != null && (
          <DataLabel
            isLoading={checkpoint == null}
            className="w-full justify-between text-md"
            label={t('trapDetails.battery')}
            data={`${checkpoint?.carbonDioxidePayload.batteryVoltage?.toString()} V`}
          />
        )}

      {checkpoint?.loraMetadata != null && checkpoint.doorPayload != null && (
        <DataLabel
          isLoading={checkpoint == null}
          className="w-full justify-between text-md"
          label={t('trapDetails.battery')}
          data={`${checkpoint?.doorPayload.batteryVoltage?.toString()} V`}
        />
      )}
      {checkpoint?.loraMetadata != null && (
        <DataLabel
          isLoading={checkpoint == null}
          className="w-full justify-between text-md"
          label={t('trapDetails.spreading')}
          data={checkpoint?.loraMetadata?.spreadingFactor?.toString()}
        />
      )}
      {checkpoint?.loraMetadata != null && (
        <DataLabel
          isLoading={checkpoint == null}
          className="w-full justify-between text-md"
          label={t('trapDetails.gatewayId')}
          data={
            checkpoint?.loraMetadata?.gatewayId?.toString() !== ''
              ? checkpoint?.loraMetadata?.gatewayId?.toString()
              : 'Kein Wert vorhanden'
          }
        />
      )}
      {checkpoint?.loraMetadata != null &&
        checkpoint.doorPayload != null &&
        checkpoint.doorPayload.doorSensorType ===
          'DOOR_SENSOR_TYPE_DISTANCE' && (
          <DataLabel
            isLoading={checkpoint == null}
            className="w-full justify-between text-md"
            label="Gemessene Distanz"
            data={`${checkpoint?.doorPayload.currentDistance?.toString()} cm`}
          />
        )}
    </Card>
  );
}

export default CheckpointState;
