import { skipToken } from '@reduxjs/toolkit/query';
import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useBuildingServiceListAreasForBuildingQuery } from '../../../../api/enhanced/enhancedBuildings';
import { V1Alpha1Checkpoint } from '../../../../api/gen/checkpoints';
import OnlineStatePill from '../../../../components/pill/onlineStatePill';
import DateTableLabel from '../../../../components/date/dateTableLabel';
import {
  enhancedCheckpointApi,
  useCheckpointServiceListCheckpointsForBuildingQuery
} from '../../../../api/enhanced/enhancedCheckpoints';
import DataTable from '../../../../components/tables/dataTable';
import RowCell from '../../../../components/tables/rowCell';
import TemperaturePill from '../../../../components/pill/temperaturePill';
import HumidityPill from '../../../../components/pill/humidityPill';
import TableDetailButtons from '../../../../components/tables/detailButtons';

interface AdminTemperatureTableProps {
  groupId?: string;
  buildingId?: string;
  editDeviceModalState: (isOpen: boolean, trapId: string) => void;
  deleteDeviceModalState: (
    isOpen: boolean,
    checkpointId: string,
    sensorId: string
  ) => void;
  searchTerm?: string;
}

function AdminTemperatureTable({
  groupId,
  buildingId,
  editDeviceModalState,
  deleteDeviceModalState,
  searchTerm
}: AdminTemperatureTableProps): ReactElement {
  const [sortColumns, setSortColumns] = useState('');
  const [sorting, setSorting] = useState<SortingState>([]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  useEffect(() => {
    let sortingColumns = 'checkpoint_number:ASC';
    if (sorting.length > 0) {
      sorting.forEach((sortCol) => {
        sortingColumns = `${sortCol.id}:${sortCol.desc ? 'DESC' : 'ASC'}`;
      });
    }
    setSortColumns(sortingColumns);
  }, [sorting, setSortColumns]);

  useEffect(() => {
    enhancedCheckpointApi.util.invalidateTags(['SENSOR_TYPE_TEMP']);
    setPagination((state) => ({ ...state, pageIndex: 0 }));
    setSortColumns('checkpoint_number:ASC');
  }, [pageSize, searchTerm]);

  const {
    data: areaData,
    isLoading: areaIsLoading,
    isSuccess: areaIsSuccess,
    isError: areaIsError,
    error: areaError
  } = useBuildingServiceListAreasForBuildingQuery(
    buildingId != null && groupId != null
      ? { buildingId, groupId, limit: 200, page: 0 }
      : skipToken
  );

  const columns = useMemo<ColumnDef<V1Alpha1Checkpoint>[]>(() => {
    const getAreaDescriptionById = (id: string): string => {
      if (areaIsError) {
        return 'Fehler beim Laden der Bereiche';
      }

      const area = areaData?.areas?.find(
        (currentArea) => currentArea.id === id
      );
      return area != null && area.description != null
        ? area.description
        : 'Keine Beschreibung vorhanden.';
    };

    return [
      {
        header: 'Prüfpunkt',
        id: 'checkpoint_number',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.checkpointNumber,
        footer: (props) => props.column.id
      },
      {
        header: 'Beschreibung',
        id: 'description',
        accessorFn: (row) => row.description,
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        footer: (props) => props.column.id
      },
      {
        header: 'Bereich',
        accessorFn: (row) => row.areaId,
        cell: (info) =>
          RowCell({
            title: getAreaDescriptionById(info.getValue<string>())
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Seriennummer',
        id: 'current_sensor_id',
        accessorFn: (row) => row.currentSensorId,
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        footer: (props) => props.column.id
      },
      {
        header: 'Temperatur',
        id: 'temperature',
        accessorFn: (row) => row.temperaturePayload?.temperature,
        cell: (info) =>
          TemperaturePill({
            value: info.getValue<number>(),
            minLimit: info.row.original.temperaturePayload?.minLimit
              ? info.row.original.temperaturePayload?.minLimit
              : 0,
            maxLimit: info.row.original.temperaturePayload?.maxLimit
              ? info.row.original.temperaturePayload?.maxLimit
              : 0
          }),
        footer: (props) => props.column.id
      },
      {
        header: 'Luftfeuchtigkeit',
        id: 'humidity',
        accessorFn: (row) => row.temperaturePayload?.humidity,
        cell: (info) =>
          HumidityPill({
            value: info.getValue<number>(),
            minLimit: info.row.original.temperaturePayload?.humidityMinLimit,
            maxLimit: info.row.original.temperaturePayload?.humidityMaxLimit
          }),
        footer: (props) => props.column.id
      },
      {
        header: 'Verfügbarkeit',
        id: 'is_online',
        accessorFn: (row) => row.loraMetadata?.isOnline,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <OnlineStatePill
            key={info.row.original.id}
            state={info.getValue<boolean>()}
            timestamp={info.row.original.loraMetadata?.lastSignal}
            checkpointId={info.row.original.id}
          />
        ),
        footer: (props) => props.column.id
      },
      {
        header: 'Letztes Signal',
        id: 'last_signal',
        cell: (info) =>
          DateTableLabel({
            timestamp: info.getValue<string>(),
            className: 'px-6'
          }),
        accessorFn: (row) => row.loraMetadata?.lastSignal,
        footer: (props) => props.column.id
      },
      {
        header: 'Batteriezustand',
        id: 'battery_voltage',
        cell: (info) => RowCell({ title: `${info.getValue<string>()} V` }),
        accessorFn: (row) => row.temperaturePayload?.batteryVoltage,
        footer: (props) => props.column.id
      },
      {
        header: 'Details',
        accessorFn: (row) => row.id,
        cell: (info) =>
          TableDetailButtons({
            editHandler: () => {
              editDeviceModalState(true, info.getValue<string>());
            },
            deleteHandler: () => {
              deleteDeviceModalState(
                true,
                info.getValue<string>(),
                info.row.original.currentSensorId!
              );
            }
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      }
    ];
  }, [
    areaData?.areas,
    areaIsError,
    deleteDeviceModalState,
    editDeviceModalState
  ]);

  const { data, isLoading, isSuccess, isError, error } =
    useCheckpointServiceListCheckpointsForBuildingQuery(
      buildingId != null && groupId != null
        ? {
            groupId,
            buildingId,
            limit: pageSize,
            page: pageIndex,
            sensorType: 'SENSOR_TYPE_TEMP',
            sortBy: sortColumns,
            searchBy: searchTerm != null ? searchTerm : ''
          }
        : skipToken
    );

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = Number(data?.total);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.checkpoints ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination, sorting },
    onSortingChange: setSorting,
    manualPagination: true,
    manualSorting: true
  });

  return (
    <DataTable
      isLoading={isLoading && areaIsLoading}
      isError={isError}
      isEmpty={
        isSuccess &&
        (data.checkpoints == null || data.checkpoints.length === 0) &&
        areaIsSuccess
      }
      pageSize={pageSize}
      table={table}
    />
  );
}

export default AdminTemperatureTable;
